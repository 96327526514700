import Pages from "./pages";
import {Route, Routes} from "react-router-dom";

function App() {
  return (
      <div>
          <Routes>
              <Route path="/" element={<Pages />} />
          </Routes>
      </div>
  );
}

export default App;
