import React from "react";

const Home = () => {
    return (
        <div>
            <div className="flex flex-col items-center bg-custom-black min-h-screen w-full justify-center space-y-10">
                <div className="text-gray-50 text-2xl font-bold">Coming Soon</div>
            </div>
        </div>
    );
};

export default Home;
